import React, { useState } from "react"
import styled from 'styled-components';
import classNames from 'classnames'

import SEO from "../components/seo";
import { Carousel } from 'react-responsive-carousel';
import Layout from "../components/layout";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const FullHeightSection = styled.section`
  min-height: 85vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  & h2 {
    margin-top: 76px;
    margin-bottom: 55px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 3.56rem;
    font-weight: 300;
    line-height: 3rem;

    &:after {
      display: block;
      content: '';
      width: 56px;
      border-top: 4px solid white;
      margin-top: 24px;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-top: 64px;
    margin-bottom: 80px;

    & h2 {
      font-size: 3rem;
      font-weight: 300;
      text-align: left;
      margin-top: 0;
    }
  }
`;

const expertiseItems = [
  {
    title: 'Strategic Planning',
    description: `We support the corporate strategic planning function in its
    decision taking process in relation to the market positioning, growth
    strategy, product segmentation, optimal portfolio management and
    CAPEX and financial planning. Our services also cover alternative
    macro economic scenario analysis, forecasting, sensitivity analysis,
    portfolio optimization and optimal growth strategies.`
  },
  {
    title: 'Market Research And Product Integration',
    description: `Prior to launching a new product, we perform for you a demand
    structure analysis, Incumbent vs Entrant Strategic Planning, analysis of
    the Market Segmentation and assessment of the concentration degree of peers
    and clients. We also analyse the possible alternatives to boost the value
    chain of your group.`
  },
  {
    title: 'Consumer Strategy and Marketing',
    description: `We offer the marketing division of your company services like
    customer segmentation analysis, optimal pricing, sales and channel
    effectiveness analysis.`
  },
  {
    title: 'Mergers and Acquisitions (M&A) and Due Dilligence',
    description: `Serving to M&A we develop asset and business valuation tasks
    as well as best practice research for the optimal peer positioning of your
    company.`
  },
  {
    title: 'Performance Improvement & Process Optimization',
    description: `Trying to boost our client’s performance we perform
    diagnostics, complexity management, working capital management, purchasing
    and G&A optimization.`
  },
  {
    title: 'Low carbon-liaison, Environmental impact analysis and planning',
    description: `In anticipation of the forthcoming environmental regulatory
    change expected along the next decade (such EU directive on low carbon
    emissions 2020) aftermath has implemented an environmental impact analysis
    an expertise line. aftermath also has a track record on carbon emission –
    pricing and trading.`
  },
];

const CarouselContainer = styled.div`
  height: 454px;
  position: relative;

  .slider {
    width: 30%;
    height: 28.375rem;
    left: 10%;
    transition-timing-function: ease-out;
  }

  .carousel-container__frame {
    position: absolute;
    left: 10%;
    width: 30%;
    height: 28.375rem;
    border: 4px solid white;
  }

  .carousel-container__indicators {
    left: 40%;
    position: absolute;
    margin-left: 32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  .carouse-container__total-indicator {
    font-weight: 700;
  }

  .carousel-container__arrows {
    display: inline-block;
    left: 40%;
    bottom: 0%;
    position: absolute;
    margin-left: 32px;
    z-index: 1;

    & .custom-arrow {
      background-color: transparent;
      content: '';
      display: inline-block;
      border: none;
      padding: 0px;
    }

    & .custom-arrow-left {
      margin-right: 25px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;

    .slider {
      width: 80%;
    }

    .carousel-container__frame {
      width: 80%;
    }

    .carousel-container__indicators {
      left: 10%;
      top: -40px;
      margin: 0;
      margin-bottom: 15px;
      font-size: 1.5rem;
    }

    .carousel-container__arrows {
      left: 10%;
      margin-left: 0;
      bottom: -40px;
    }
  }
`;

const StyledCarouselItem = styled.div`
  padding: 48px 32px;
  text-align: left;

  .carousel-item__title, .carousel-item__description {
    transition: all .6s;
  }

  .carousel-item__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
  }

  .carousel-item__description {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  .carousel-item__separator {
    display: block;
    width: 56px;
    border-top: 2px solid white;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  &:not(.selected) {
    cursor: pointer;

    .carousel-item__separator, .carousel-item__title {
      opacity: .5;
    }

    .carousel-item__description {
      opacity: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 24px;

    &:not(.selected) {
      .carousel-item__description {
        opacity: .5;
      }
    }
  }
`;

const CarouselItem = (props) => {
  const item = props.item;
  const isSelected = props.isSelected;

  return (
    <StyledCarouselItem className={ classNames({'selected': isSelected}) }>
      <h3 className='carousel-item__title'>{item.title}</h3>
      <span className='carousel-item__separator'></span>
      <p className='carousel-item__description'>{item.description}</p>
    </StyledCarouselItem>
  );
};

const CarouselArrows = (props) => {
  const selectedItem = props.selectedItem;
  const maxItems = props.maxItems;
  const setSelectedItem = props.setSelectedItem;

  const prevItem = (selectedItem - 1) % maxItems;
  const nextItem = (selectedItem + 1) % maxItems;

  return (
    <div className={ props.className }>
      <button className='custom-arrow custom-arrow-left' onClick={ () => setSelectedItem(prevItem) }>
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b)">
            <path d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z" fill="white"/>
          </g>
          <defs>
            <filter id="filter0_b" x="-64" y="-64" width="135.41" height="140" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImage" stdDeviation="32"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
            </filter>
          </defs>
        </svg>
      </button>
      <button className='custom-arrow custom-arrow-right' onClick={ () => setSelectedItem(nextItem) }>
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b)">
            <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z" fill="white"/>
          </g>
          <defs>
            <filter id="filter0_b" x="-63.4102" y="-64" width="135.41" height="140" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImage" stdDeviation="32"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
            </filter>
          </defs>
        </svg>
      </button>
    </div>
  );
};


const IndexPage = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <Layout>
      <SEO title="Expertise" />
      <FullHeightSection className='expertise'>
        <h2>expertise</h2>
        <CarouselContainer className='carousel-container'>
          <div className='carousel-container__frame' />
          <div className='carousel-container__indicators'>
            { (selectedItem + 1).toString().padStart(2, '0') }<span className='carouse-container__total-indicator'>/{ expertiseItems.length.toString().padStart(2, '0') }</span>
          </div>
          <CarouselArrows
              className='carousel-container__arrows'
              selectedItem={ selectedItem }
              maxItems={ expertiseItems.length }
              setSelectedItem={ setSelectedItem }
            />
          <Carousel
              autoPlay={ true }
              centerMode={ true }
              centerSlidePercentage={ 100 }
              showArrows={ false }
              showIndicators={ false }
              showStatus={ false }
              showThumbs={ false }
              selectedItem={ selectedItem }
              onChange={ (idx, item) => {
                setSelectedItem(idx);
              }}
              onClickItem={ (idx, item) => {
                setSelectedItem(idx);
              }}
              transitionTime={ 300 }
              interval={ 7000 }
          >
            { expertiseItems.map((item, index) => {
              return <CarouselItem item={item} isSelected={index === selectedItem} />
            }) }
          </Carousel>
        </CarouselContainer>
      </FullHeightSection>
    </Layout>
  )
}

export default IndexPage
